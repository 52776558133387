<template>
    <section>
        <div class="d-flex mx-0">
            <div class="col-auto pt-3 px-3">
                <div class="btn-back f-14" @click="$router.push({name: 'admin.tesoreria.cuentas-bancarias'})">
                    <i class="icon-left" />
                </div>
            </div>
            <div class="col pl-0">
                <tabla-general :data="dataTable" :columnas="dataColumns" class-header="text-general f-16" alto="calc(100vh - 175px)">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto f-600 text-general f-18">
                            Lista de cuentas bancarias
                        </div>
                        <div v-if="$can('botones_flujo_dinero_cuentas_gestionar')" class="col d-middle">
                            <div class="btn-general px-3" @click="crearCuenta">
                                Añadir cuenta
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column sortable prop="created_at" label="Creación" class-name="text-center">
                            <template slot-scope="{ row }">
                                <div class="row mx-0 justify-center">
                                    {{ formatearFecha(row.created_at) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ row }">
                                <div class="row mx-0 justify-center">
                                    <el-tooltip placement="bottom" :content="!row.estado ? 'Activar' : 'Inactivar'" effect="light">
                                        <el-switch v-model="row.estado" active-color="#28D07B" :disabled="!$can('botones_flujo_dinero_cuentas_gestionar')" inactive-color="#DBDBDB" @change="cambioEstadoCuentaBancaria(row)" />
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="70">
                            <template slot-scope="{ row }">
                                <div v-if="$can('botones_flujo_dinero_cuentas_gestionar')" class="btn-action border" @click="editarCuenta(row)">
                                    <i class="icon-pencil-outline f-17 text-general" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="70" class="cr-pointer">
                            <template slot-scope="{ row }">
                                <div v-if="$can('botones_flujo_dinero_cuentas_gestionar')" class="btn-action border" @click="eliminar(row)">
                                    <i class="icon-delete-outline f-17 text-general" />
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-cuenta ref="modalCrearCuenta" @crear="crear" />
        <modal-editar-cuenta ref="modalEditarCuenta" @editar="editar" />
        <modal-eliminar ref="modalEliminar" titulo="Eliminar cuenta" mensaje="¿Desea eliminar esta cuenta?" @eliminar="eliminarCuentaBancaria" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import CuentasBancarias from "~/services/flujoDinero/cuentas_bancarias";
export default {
    components: {
        modalCrearCuenta: () => import('./partials/modalCrearCuenta'),
        modalEditarCuenta: () => import('./partials/modalEditarCuenta'),
    },
    data(){
        return {
            dataTable: [],
            dataColumns: [
                {valor: 'nombre', titulo: 'Nombre', class: 'text-center'},
                {valor: 'cedis_count', titulo: 'Cedis', class: 'text-center'},
                {valor: 'descripcion', titulo: 'Descripcion'}
            ],
            idCuenta: null
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda == null){
                    setTimeout(async() => {
                        await this.tablaCuentasBancarias()
                    }, 1000);
                }else{
                    await this.tablaCuentasBancarias()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async tablaCuentasBancarias(){
            try {
                const { data } = await CuentasBancarias.tablaCuentasBancarias()
                this.dataTable = data.data.cuentasBancarias
            } catch (error){
                this.error_catch(error)
            }
        },
        crearCuenta(){
            this.$refs.modalCrearCuenta.toggle()
        },
        editarCuenta(model){
            this.$refs.modalEditarCuenta.toggle(model)
        },
        crear(model){
            this.dataTable.push(model)
        },
        editar(model){
            let index = this.dataTable.findIndex(el => el.id == model.id)
            this.$set(this.dataTable, index, model)
        },
        eliminar({ id }){
            this.idCuenta = id
            this.$refs.modalEliminar.toggle();
        },
        async eliminarCuentaBancaria(){
            try {
                const {data} = await CuentasBancarias.eliminarCuentaBancaria(this.idCuenta)
                this.notificacion('', data.message, 'success')
                let index = this.dataTable.findIndex(el => el.id == this.idCuenta)
                this.dataTable.splice(index, 1)
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioEstadoCuentaBancaria({id}){
            try {
                const {data} = await CuentasBancarias.cambioEstadoCuentaBancaria(id)
                this.notificacion('', data.message, 'success')
                // let index = this.dataTable.findIndex(el => el.id == model.id)
                // this.$set(this.dataTable, index, model)
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-caja{
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
}
</style>
